import { Row, Table } from "@tanstack/react-table";
import React, { HTMLProps } from "react";

const useSelectColumn = (
  onSelectAll?: ((arg0: boolean) => void) | undefined
) => {
  function IndeterminateCheckbox({
    indeterminate,
    className = "",
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    return (
      <input
        type="checkbox"
        ref={ref}
        className={className + "cursor-pointer"}
        {...rest}
      />
    );
  }

  const selectAllChecked = (table: Table<unknown>) => {
    const checked = table.getIsAllRowsSelected();
    onSelectAll?.(checked);
    return checked;
  };

  const selectColumn = {
    id: "select",
    header: ({ table }: { table: Table<unknown> }) => (
      <div
        className="flex justify-center w-full h-full"
        onClick={(e) => {
          e.stopPropagation();
          table.getToggleAllRowsSelectedHandler()(e);
        }}
      >
        <IndeterminateCheckbox
          {...{
            checked: selectAllChecked(table),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      </div>
    ),
    cell: ({ row }: { row: Row<unknown> }) => (
      <div
        className="flex py-2.5 justify-center w-full min-h-full"
        onClick={(e) => {
          e.stopPropagation();
          row.toggleSelected();
        }}
      >
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
    disableResizing: true,
    size: 33,
    maxWidth: 33,
    minWidth: 33,
  };

  return selectColumn;
};

export default useSelectColumn;
