import React from "react";
import SearchSortTable from "components/Table";
import { ColumnDef, Row } from "@tanstack/react-table";
import useTableColumns from "./useTableColumns";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { useParams } from "react-router";
import BulkActionBar from "./layout/BulkActionBar";
import FiltersBar from "./Filters";
import useMappingEditor from "./useMappingEditor";
import { GroupType } from "store/IfcMapping/DME/types";
import EmptyState from "./layout/EmptyState";
import { observer } from "mobx-react-lite";
import { SingleObject } from "api-client";
import { bimViewerStore } from "store/BimViewerStore";

const DMETableView = () => {
  const { ifc_id } = useParams();
  const { updateColumnsVisibilitySavedState, getColumnsOrderSavedState } =
    useMappingEditor();

  React.useEffect(() => {
    initialCall();

    return () => dynamicMEStore.resetData();
  }, []);

  async function initialCall() {
    /**
     * This could be reduced to one API call in the future since
     * they are different structures of the same data
     */
    const [dynamicColumns] = await Promise.all([
      dynamicMEStore.fetchColumns(ifc_id),
      dynamicMEStore.getProperties(ifc_id),
    ]);
    updateColumnsVisibilitySavedState(dynamicColumns ?? []);
    getColumnsOrderSavedState();
  }

  const getColumns = () => {
    return useTableColumns();
  };

  const getData = async (start: number, _: number, update?: boolean) => {
    await dynamicMEStore.fetchObjectsList(start, update, ifc_id);
  };

  const onClickCell = (row: Row<unknown>) => {
    dynamicMEStore.setCurrentRow(row);
    dynamicMEStore.setOpenedObjectDetails(true);
  };

  const handleOnHoverRow = (row: Row<unknown> | null) => {
    if (!dynamicMEStore.open3DViewer) return;
    const ifc_id = (row?.original as SingleObject)?.ifc_global_id as string;
    bimViewerStore.setHovered(
      ifc_id || null,
      (dynamicMEStore.currentRow?.original as SingleObject)?.ifc_global_id,
      dynamicMEStore.selectedRows.length > 0
    );
  };

  const noResultsForFiltering = () =>
    dynamicMEStore.selectedFilterByItems.rules.length &&
    dynamicMEStore.data.items.length === 0 &&
    !dynamicMEStore.dataFetchLoading &&
    !dynamicMEStore.errorFetchingObjects;

  const showTable = !dynamicMEStore.errorFetchingObjects;

  const infoColumns = dynamicMEStore.visibleColumns.find(
    (item) => item.group === GroupType.infoColumns
  )?.columns;

  if (!infoColumns?.length) return <></>;
  return (
    <div className="h-screen flex flex-col items-center w-full">
      <FiltersBar />
      {dynamicMEStore.errorFetchingObjects && <EmptyState />}
      {noResultsForFiltering() ? (
        <EmptyState />
      ) : (
        showTable && (
          <SearchSortTable
            getColumns={getColumns as () => ColumnDef<unknown, unknown>[]}
            data={dynamicMEStore.data.items.map((item) => ({
              ...item.values,
              product_match: item.product_match,
              ifc_global_id: item.ifc_global_id,
            }))}
            hasGroupHeader
            enableColumnResizing
            totalDBRowCount={dynamicMEStore.data.count}
            dataFetchLoading={dynamicMEStore.dataFetchLoading}
            fetchData={getData}
            enableTooltip
            scrollDivClassName="!max-h-[calc(100vh-145px)] w-full"
            customColumnStyle="!py-0 [&:nth-of-type(1)]:px-0 !font-medium h-[40px] cursor-pointer"
            headerDivStyle="!py-1.5"
            headerColumnStyle="!py-0"
            customContainerStyle="border-b"
            groupHeaderClassName="!pt-0.5 !pb-0.5"
            enableRowSelection
            onRowSelect={(e) => dynamicMEStore.setSelectedRows(e)}
            noFooterBar
            hasHoverStyle
            onRowClick={onClickCell}
            selectedRows={dynamicMEStore.selectedRowIndexes}
            selectAllChecked={dynamicMEStore.selectAllChecked}
            hiddenColumns={dynamicMEStore.columnsVisibilityState}
            columnsOrder={dynamicMEStore.tableColumnsOrder}
            currentRowID={
              (dynamicMEStore.currentRow?.original as SingleObject)?.id
            }
            disableUserInteraction={dynamicMEStore.interactionDisabled}
            onHoveredRow={handleOnHoverRow}
          />
        )
      )}
      <BulkActionBar />
    </div>
  );
};

export default observer(DMETableView);
