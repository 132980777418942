import React from "react";
import Button from "components/Button";
import { useLocation, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Badge from "components/Badge";
import LinearLoading from "components/LinearLoading";
import { observer } from "mobx-react-lite";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import { buildingStore } from "store/Building/BuildingStore";
import { IconHelpCircle } from "@tabler/icons-react";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { DME_HELP_URL } from "../const";
import useFeatureFlag from "hooks/useFeatureFlag";

export default observer(function MappingEditorHeader() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { ifc_id } = useParams();
  const { fileMappingStatus, mappingPerformed } = ifcMappingStore;
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const { isFeatureVisible } = useFeatureFlag();
  const isBIMFeatureVisible = isFeatureVisible("bim_viewer");

  const fileSRC = buildingStore.materialResources.items.find(
    (item) => item.id === ifc_id
  )?.file_processed_xkt;

  React.useEffect(() => {
    ifcMappingStore.getObjectsMappingStatus(ifc_id);
  }, []);

  function mappingProgressBar() {
    return !fileMappingStatus ? (
      skeleton()
    ) : (
      <div className="h-full">
        <div className="text-xs pb-1 text-gray-600">
          <span>
            {t("mappingEditor.objectsMappedStatus", {
              objects: fileMappingStatus?.mapped_objects,
              total: fileMappingStatus?.total,
            })}
          </span>
        </div>
        <div className="h-1 relative max-w-xl rounded-full overflow-hidden scrollbar">
          <div className="w-full h-full bg-green-100 absolute"></div>
          <div
            className="h-full bg-green-500 absolute"
            style={{
              width: `${
                (fileMappingStatus?.mapped_objects * 100) /
                fileMappingStatus?.total
              }%`,
            }}
          ></div>
        </div>
      </div>
    );
  }

  async function onDone() {
    if (mappingPerformed) {
      setLoading(true);
      await buildingStore.getAllMaterialResources();
      ifcMappingStore.setMappingPerformed(false);
      setLoading(false);
    }
    navigate("../material-resources", { replace: true });
  }

  const open3DViewer = () => dynamicMEStore.setOpen3DViewer(true);

  function buttons(className: string) {
    return (
      <div className={className}>
        <Button
          width="fit-content"
          className="mr-4"
          onClick={() =>
            window.open(DME_HELP_URL, "_blank", "noopener,noreferrer")
          }
          testID={"donemapping_button"}
          loading={loading}
          type="gray"
          leadingIcon={<IconHelpCircle />}
        >
          {t("mappingEditor.help")}
        </Button>
        {isBIMFeatureVisible && fileSRC && (
          <Button
            type="gray"
            className="mr-4"
            onClick={open3DViewer}
            disable={dynamicMEStore.open3DViewer}
          >
            {t("mappingEditor.3DView")}
          </Button>
        )}
        <Button
          width="fit-content"
          onClick={onDone}
          testID={"donemapping_button"}
          loading={loading}
        >
          {t("mappingEditor.done")}
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className="relative">
        {(dynamicMEStore.showMappingLoading ||
          dynamicMEStore.dataFetchLoading) && <LinearLoading type="success" />}
      </div>
      <div
        id="editor-header"
        className="sm:flex w-full bg-white px-4 py-3.5 items-center justify-between border-b border-gray-300"
      >
        <div id="editor-title" className="sm:flex mb-3 sm:mb-0">
          <div className="flex justify-between">
            <div className="text-xl font-semibold text-black mr-6 mb-2 sm:mb-0">
              {t("mappingEditor.mappingEditor")}
            </div>
            {buttons("sm:hidden flex")}
          </div>
          {location.state ? (
            <Badge type="info" className="mr-6 mb-2 sm:mb-0">
              {`${location?.state}`}
            </Badge>
          ) : null}
          {mappingProgressBar()}
        </div>
        {buttons("sm:flex hidden")}
      </div>
    </>
  );
});

function skeleton() {
  return (
    <div className="animate-pulse mt-1.5">
      <div className="w-[200px] h-[20px] bg-gray-200 rounded dark:bg-gray-700"></div>
    </div>
  );
}
