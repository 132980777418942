import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Entity } from "@xeokit/xeokit-sdk";
import { observer } from "mobx-react-lite";
import { bimViewerStore } from "store/BimViewerStore";
import { useParams } from "react-router";
import Button from "components/Button";
import {
  IconChevronsLeft,
  IconChevronsRight,
  IconCrop,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ButtonGroups from "components/ButtonGroups";
import DetailsView from "features/MappingTools/ObjectDetailsSlideOver/DetailsView";
import { buildingStore } from "store/Building/BuildingStore";
import useBimViewerContextMenu from "./useBimViewerContextMenu";

interface TreeViewProps {
  id: string;
  name?: string;
}

export const hierarchies = {
  containment: "containment",
  storeys: "storeys",
  types: "types",
};

const hierarchyItems = [
  { id: hierarchies.containment, name: "IFC objects" },
  { id: hierarchies.types, name: "IFC classes" },
  { id: hierarchies.storeys, name: "Storeys" },
];

export default observer(function BimViewer() {
  const viewerRef = useRef<HTMLCanvasElement>(null);
  const treeViewContainmentRef = useRef<HTMLDivElement>(null);
  const treeViewTypesRef = useRef<HTMLDivElement>(null);
  const treeViewStoreysRef = useRef<HTMLDivElement>(null);
  const [openDetailView, setOpenDetailView] = useState<boolean>(false);
  const [openTreeView, setOpenTreeView] = useState<boolean>(true);
  const { ifc_id } = useParams();
  const { materialResources } = buildingStore;
  const fileSrc = materialResources.items.find(
    (item) => item.id === ifc_id
  )?.file_processed_xkt;
  const { t } = useTranslation();
  const [selectedTreeView, setSelectedTreeView] = useState<TreeViewProps>(
    hierarchyItems[0]
  );
  const menu = useBimViewerContextMenu();

  const containersReady = () =>
    viewerRef.current &&
    treeViewContainmentRef.current &&
    treeViewStoreysRef.current &&
    treeViewTypesRef.current;

  useEffect(() => {
    if (containersReady() && fileSrc) {
      const viewer = bimViewerStore.createViewer("bimViewerCanvas");
      bimViewerStore.setViewer(viewer);
      bimViewerStore.loadFile(fileSrc);
      bimViewerStore.handleObjectsOnclick(goToObjectDetail);
      bimViewerStore.addNavCube();
      const treeView = bimViewerStore.addTreeView(
        treeViewContainmentRef.current,
        "containment"
      );
      const typesTreeView = bimViewerStore.addTreeView(
        treeViewTypesRef.current,
        "types"
      );
      const storeysTreeView = bimViewerStore.addTreeView(
        treeViewStoreysRef.current,
        "storeys"
      );

      menu.createTreeViewContextMenu(treeView);
      menu.createTreeViewContextMenu(typesTreeView);
      menu.createTreeViewContextMenu(storeysTreeView);
      const objectContextMenu = menu.createObjectsContextMenu();
      const canvasContextMenu = menu.createCanvasContextMenu(
        bimViewerStore.viewer
      );
      menu.controlRightMenuOnViewer(
        bimViewerStore.viewer,
        objectContextMenu,
        treeView,
        canvasContextMenu
      );

      return () => {
        viewer.destroy();
        objectContextMenu.destroy();
        canvasContextMenu?.destroy();
      };
    }
  }, [materialResources]);

  async function goToObjectDetail(object: Entity) {
    if (!ifc_id) return;
    await bimViewerStore.fetchDetailsInfo(ifc_id, String(object.id));
    bimViewerStore.setCurrentObject(object);
    setOpenDetailView(true);
  }

  function closeDetailView() {
    setOpenDetailView(false);
    bimViewerStore.setObjectSelectStatus(false, true);
  }

  function fileExplorer() {
    return (
      <div
        className={clsx(
          "fixed h-full transition-all duration-500 top-17 w-fit",
          `${openTreeView ? "left-0" : "-left-[350px]"}`
        )}
      >
        <div className="flex h-full">
          <div className="shadow-md w-[350px] bg-white bg-opacity-70">
            <div
              className={clsx(
                "flex justify-between border-b border-gray-100 w-full bg-white font-semibold text-lg text-gray-900 py-2 px-5"
              )}
            >
              <div>{t("bimViewer.fileExplorer")}</div>
              <IconChevronsLeft
                className="cursor-pointer text-gray-500 hover:text-gray-900 transition"
                onClick={() => setOpenTreeView(false)}
              />
            </div>
            <ButtonGroups
              containerClassName="px-6 pt-4"
              items={hierarchyItems}
              onChange={(e) => e && setSelectedTreeView(e)}
              current={selectedTreeView}
            />
            <div
              id="treeViewContainment"
              ref={treeViewContainmentRef}
              className={clsx(
                "px-6 py-4 bg-white bg-opacity-50 w-full h-full overflow-y-scroll treeViewContainer",
                hierarchies.containment === selectedTreeView.id
                  ? "visible"
                  : "hidden"
              )}
            ></div>
            <div
              id="treeViewStoreys"
              ref={treeViewStoreysRef}
              className={clsx(
                "px-6 py-4 bg-white bg-opacity-50 w-full h-full overflow-y-scroll treeViewContainer",
                hierarchies.storeys === selectedTreeView.id
                  ? "visible"
                  : "hidden"
              )}
            ></div>
            <div
              id="treeViewTypes"
              ref={treeViewTypesRef}
              className={clsx(
                "px-6 py-4 bg-white bg-opacity-50 w-full h-full overflow-y-scroll treeViewContainer",
                hierarchies.types === selectedTreeView.id ? "visible" : "hidden"
              )}
            ></div>
          </div>
          <div className="bg-white h-fit m-5 rounded-md p-3 shadow-sm flex">
            {!openTreeView ? (
              <Button
                type="gray"
                onClick={() => setOpenTreeView(true)}
                width="fit-content"
                className="mr-3"
                trailingIcon={<IconChevronsRight />}
              >
                {t("bimViewer.openExplorer")}
              </Button>
            ) : null}
            {bimViewerStore.viewer && (
              <Button
                type="gray"
                onClick={() => bimViewerStore.zoomToBuilding()}
                width="fit-content"
                trailingIcon={<IconCrop />}
              >
                {t("bimViewer.viewFit")}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  const refreshAfterMatching = async () => {
    if (!ifc_id) return;
    await bimViewerStore.fetchDetailsInfo(
      ifc_id,
      String(bimViewerStore.currentObject?.id)
    );
  };

  return (
    <div className="flex relative h-full">
      <canvas
        ref={viewerRef}
        id="bimViewerCanvas"
        className="bg-indigo-50 w-full h-[100vh]"
      ></canvas>
      <canvas id="myNavCubeCanvas"></canvas>
      {fileExplorer()}
      <DetailsView
        open={openDetailView}
        close={closeDetailView}
        physicalObjectDetails={
          bimViewerStore.objectDetail ? [...bimViewerStore.objectDetail] : []
        }
        refreshAfterMatching={refreshAfterMatching}
      />
    </div>
  );
});
