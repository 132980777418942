import React from "react";
import { observer } from "mobx-react-lite";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { IconCapsuleHorizontal, IconLayoutRows } from "@tabler/icons-react";
import useFeatureFlag from "hooks/useFeatureFlag";
import { useParams } from "react-router";
import ButtonGroups from "components/ButtonGroups";
import clsx from "clsx";
import AdvancedFilterView from "./ViewLayouts/AdvancedFilterView";
import SimpleFilterView from "./ViewLayouts/SimpleFilterView";

export const FILTER_VIEW_OPTIONS = [
  { id: "simple", icon: <IconCapsuleHorizontal size={18} stroke={3} /> },
  { id: "advanced", icon: <IconLayoutRows size={18} stroke={3} /> },
];

const FiltersBar = () => {
  const { isFeatureVisible } = useFeatureFlag();
  const { ifc_id } = useParams();

  React.useEffect(() => {
    dynamicMEStore.fetchObjectsList(0, true, ifc_id);
    dynamicMEStore.setSelectedRows([]);
  }, [dynamicMEStore.validSortingRules, dynamicMEStore.validFilterRules]);

  if (!isFeatureVisible("mapping_editor_filtering")) return <></>;
  return (
    <div
      className={clsx(
        "w-full px-4 border-b flex justify-between text-xs font-medium items-center",
        "border-gray-300",
        { "h-[42px]": dynamicMEStore.selectedFilterView?.id === "simple" }
      )}
    >
      {dynamicMEStore.selectedFilterView?.id === "simple" ? (
        <SimpleFilterView />
      ) : (
        <AdvancedFilterView />
      )}
      <ButtonGroups
        optionClsssName="!h-[26px] px-2 py-1 !rounded-full border-none"
        containerClassName="border border-gray-300 rounded-full"
        groupClassName="!mt-0"
        onChange={(value) => dynamicMEStore.setSelectedFilterView(value)}
        items={FILTER_VIEW_OPTIONS}
        current={FILTER_VIEW_OPTIONS[0]}
      ></ButtonGroups>
    </div>
  );
};

export default observer(FiltersBar);
