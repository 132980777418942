import React from "react";
import { observer } from "mobx-react-lite";
import { IconPlus, IconSettings } from "@tabler/icons-react";
import SelectorMenu from "./SelectorMenu";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import DropdownBrowser, { DropdownOption } from "components/DropdownBrowser";
import { useTranslation } from "react-i18next";
import { PropertyBrowserTreeNode } from "api-client";
import useMappingEditor from "../useMappingEditor";
import { useParams } from "react-router";

export default observer(function ColumnSelectorBrowser({
  groupName,
}: {
  groupName: string;
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { ifc_id } = useParams();
  const [cursorPosition, setCursorPosition] = React.useState({ x: 0, y: 0 });
  const { saveColumnsVisibilityState, saveColumnsOrder } = useMappingEditor();

  const filteredItems = dynamicMEStore.filterNotSelectedItems(
    dynamicMEStore.properties,
    dynamicMEStore.visibleColumns[0].columns
  );

  const onAddColumn = async (value: DropdownOption[]) => {
    const updatedList = dynamicMEStore.visibleColumns.map((item) => {
      return {
        ...item,
        columns: value as PropertyBrowserTreeNode[],
      };
    });
    dynamicMEStore.setVisibleColumns(updatedList);
    saveColumnsVisibilityState();
    saveColumnsOrder();
    dynamicMEStore.fetchObjectsList(0, true, ifc_id);
    closeView();
  };

  const closeView = () =>
    dynamicMEStore.setOpenColumnSettings({
      ...dynamicMEStore.openColumnSettings,
      open: false,
    });

  React.useEffect(() => {
    document.addEventListener("click", closeView);
    return () => {
      document.removeEventListener("click", closeView);
    };
  }, []);

  const handleOnClick = (e: { clientX: number; clientY: number }) => {
    dynamicMEStore.setOpenColumnSettings({
      name: groupName,
      open:
        groupName != dynamicMEStore.openColumnSettings.name
          ? true
          : !dynamicMEStore.openColumnSettings.open,
    });
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  const onOpenPropertyBrowser = (open: boolean) =>
    dynamicMEStore.setOpenAddColumns(open);

  return (
    <div
      className="overflow-visible flex text-gray-900"
      onClick={(e) => e.stopPropagation()}
    >
      <DropdownBrowser<PropertyBrowserTreeNode>
        displayKey={isEng ? "name" : "name_de"}
        handleSelect={onAddColumn}
        items={filteredItems}
        checkedItems={dynamicMEStore.visibleColumns[0].columns}
        placeholder={t("mappingEditor.findProperty")}
        optionsClassName="!max-w-fit min-w-[246px] !right-0"
        fixedPos
        isOpen={onOpenPropertyBrowser}
        titleOrIcon={
          <IconPlus
            className="cursor-pointer"
            stroke={2.5}
            size={18}
            onClick={closeView}
          />
        }
      />

      <IconSettings
        className="cursor-pointer ml-1"
        stroke={2.5}
        size={18}
        onClick={handleOnClick}
      />

      {dynamicMEStore.openColumnSettings.open &&
      dynamicMEStore.openColumnSettings.name === groupName ? (
        <SelectorMenu
          cursorPosition={cursorPosition}
          close={closeView}
          onAddColumn={onAddColumn}
          filteredItems={filteredItems}
        />
      ) : null}
    </div>
  );
});
