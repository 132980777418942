import {
  DynamicColumnOUT,
  FilteringRule,
  LogicalOperatorENUM,
  PropertyBrowserTreeNode,
  Sorting,
} from "api-client";
import { FilterRule } from "features/MappingTools/DynamicMappingEditor/Filters/FilterByPropertyBrowser";

export interface SortingOption extends Sorting {
  name?: string;
  name_de?: string;
}

export interface FilteringRuleExtended extends FilteringRule {
  name?: string;
  name_de?: string;
}
export interface FilteringOption {
  logical_operator: LogicalOperatorENUM;
  rules: FilterRule[];
}

export interface ColumnGroup {
  group: string;
  columns: DynamicColumnOUT[];
}

export interface ColumnProps {
  column_key: string;
  column_source: string;
  name: string;
  name_de: string;
}

export interface ColumnBrowserOpen {
  name: string;
  open: boolean;
}

export const GroupType = {
  infoColumns: "infoColumns",
  groupingColumns: "groupingColumns",
};

export interface VisibleColumns {
  group: string;
  columns: PropertyBrowserTreeNode[];
}

export type ColumnVisibilityState = {
  [key: string]: boolean;
};
