import {
  DynamicColumnOUT,
  ObjectOperationIN,
} from "./../../../api-client/generated/api";
import {
  DEFAULT_SORT_ROW,
  EMPTY_FILTER_RULE,
} from "./../../../features/MappingTools/DynamicMappingEditor/const";
import { DropdownOption } from "components/DropdownCheckbox";
import { Row } from "@tanstack/react-table";
import { makeAutoObservable } from "mobx";
import {
  ColumnSourceEnum,
  ColumnToDisplay,
  FileOperationIN,
  Filtering,
  FilteringRule,
  FilteringRuleENUM,
  MatchObjectsIN,
  ObjectListIN,
  ObjectListOUT,
  OperationType,
  PropertyBrowserTreeNode,
  SingleObject,
  SortDirectionENUM,
  Sorting,
  dynamicMappingEditorApi,
  fileOperationsApi,
  mappingEditorDynamicColumns,
  mappingEditorObjectsApi,
  objectOperationsApi,
} from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import {
  ColumnBrowserOpen as ColumnSettings,
  ColumnGroup,
  FilteringOption,
  GroupType,
  SortingOption,
  VisibleColumns,
  ColumnVisibilityState,
} from "./types";
import { SortProps } from "features/MappingTools/DynamicMappingEditor/Filters/SortByPropertyBrowser";
import { BGItem } from "components/ButtonGroups";
import { FILTER_VIEW_OPTIONS } from "features/MappingTools/DynamicMappingEditor/Filters";
import { FilterRule } from "features/MappingTools/DynamicMappingEditor/Filters/FilterByPropertyBrowser";
import { ifcMappingStore } from "../IFCMappingStore";

class DMEStore {
  currentRow: Row<unknown> | undefined = undefined;
  selectedRows: SingleObject[] = [];
  selectedRowIndexes: string[] = [];
  selectAllChecked: boolean = false;
  dataFetchLoading: boolean = false;
  data: ObjectListOUT = {
    items: [],
    count: 0,
  };
  openedProductDetailsPopup: boolean = false;
  openedObjectDetails: boolean = false;
  selectedGroupByItems: DropdownOption[] = [];
  selectedFilterByItems: FilteringOption = {
    logical_operator: "AND",
    rules: [{ ...EMPTY_FILTER_RULE }],
  };
  selectedSortedByItems: SortProps[] = [DEFAULT_SORT_ROW];
  columnGroups: ColumnGroup[] = [{ group: GroupType.infoColumns, columns: [] }];
  openFilter: boolean = false;
  openSort: boolean = false;
  selectedFilterView: BGItem | undefined = FILTER_VIEW_OPTIONS[0];
  validFilterRules: Filtering | null = null;
  validSortingRules: Sorting[] | null = null;
  showMappingLoading: boolean = false;
  matchForSelectedItems: boolean = false;
  visibleColumns: VisibleColumns[] = [];
  openColumnSettings: ColumnSettings = {
    name: "",
    open: false,
  };
  properties: PropertyBrowserTreeNode[] = [];
  columnsVisibilityState: ColumnVisibilityState = {};
  columnsOrder: string[] = [];
  errorFetchingObjects: boolean = false;
  open3DViewer: boolean = false;
  allObjectsIfcIDs: string[] = [];
  openAddColumns: boolean = false;

  setCurrentRow(currentRow: Row<unknown> | undefined) {
    this.currentRow = currentRow;
  }

  setSelectedRows(selectedRowsIndexes: string[]) {
    this.selectedRowIndexes = selectedRowsIndexes;
    this.selectedRows = selectedRowsIndexes.map((index) => {
      return this.data.items[Number(index)];
    });
  }

  setSelectAllChecked(selectAllChecked: boolean) {
    this.selectAllChecked = selectAllChecked;
  }

  setData(data: ObjectListOUT) {
    this.data = data;
  }

  setColumnGroups(columns: ColumnGroup[]) {
    this.columnGroups = columns;
  }

  setDataFetchLoading(dataFetchLoading: boolean) {
    this.dataFetchLoading = dataFetchLoading;
  }

  setOpenedProductDetailsPopup(openedDetailsPopup: boolean) {
    this.openedProductDetailsPopup = openedDetailsPopup;
  }

  setOpenedObjectDetails(openedObjectDetails: boolean) {
    this.openedObjectDetails = openedObjectDetails;
  }

  setSelectedGroupByItems(selectedGroupByItems: DropdownOption[]) {
    this.selectedGroupByItems = selectedGroupByItems;
  }

  setSelectedFilterByItems(selectedFilterByItems: FilteringOption) {
    this.selectedFilterByItems = selectedFilterByItems;
    this.getFilterProps();
  }

  setSelectedSortedByItems(selectedSortedByItems: SortProps[]) {
    this.selectedSortedByItems = selectedSortedByItems;
    this.getSortingProps();
  }

  setOpenFilter(openFilter: boolean) {
    if (openFilter) {
      this.setOpenSort(false);
      this.openColumnSettings.open = false;
    }
    this.openFilter = openFilter;
  }

  setOpenSort(openSort: boolean) {
    if (openSort) {
      this.setOpenFilter(false);
      this.openColumnSettings.open = false;
    }
    this.openSort = openSort;
  }

  setOpenAddColumns(openAddColumns: boolean) {
    this.openAddColumns = openAddColumns;
  }

  setSelectedFilterView(selectedFilterView: BGItem | undefined) {
    this.selectedFilterView = selectedFilterView;
  }

  setShowMappingLoading(showMappingLoading: boolean) {
    this.showMappingLoading = showMappingLoading;
  }

  setMatchForSelectedItems(matchForSelectedItems: boolean) {
    this.matchForSelectedItems = matchForSelectedItems;
  }

  setOpenColumnSettings(openColumnSettings: ColumnSettings) {
    this.openColumnSettings = openColumnSettings;
  }

  setVisibleColumns(visibleColumns: VisibleColumns[]) {
    this.visibleColumns = visibleColumns;
  }

  setProperties(properties: PropertyBrowserTreeNode[]) {
    this.properties = properties;
  }

  setColumnsVisibilityState(columnsState: ColumnVisibilityState) {
    this.columnsVisibilityState = columnsState;
  }

  setColumnsOrder(columnsOrder: string[]) {
    this.columnsOrder = columnsOrder;
  }

  setErrorFetchingObjects(errorFetchingObjects: boolean) {
    this.errorFetchingObjects = errorFetchingObjects;
  }

  setOpen3DViewer(open3DViewer: boolean) {
    this.open3DViewer = open3DViewer;
  }

  resetData() {
    this.data = {
      items: [],
      count: 0,
    };
    this.properties = [];
    this.allObjectsIfcIDs = [];
    this.selectedFilterByItems = {
      logical_operator: "AND",
      rules: [{ ...EMPTY_FILTER_RULE }],
    };
    this.selectedGroupByItems = [];
    this.selectedSortedByItems = [DEFAULT_SORT_ROW];
    this.validFilterRules = null;
    this.validSortingRules = null;
    this.currentRow = undefined;
    this.openedObjectDetails = false;
  }

  get tableColumnsOrder(): string[] {
    return ["select", ...this.columnsOrder];
  }

  private updateData(receivedData: ObjectListOUT, update?: boolean) {
    if (receivedData && !receivedData.items) return;
    if (update && receivedData) {
      this.setData(receivedData);
    } else if (!update && receivedData) {
      this.setData({
        items: [...this.data.items, ...receivedData.items],
        count: receivedData.count,
      });
    }
  }

  private validFilter(rule: FilterRule) {
    const noValueNeeded = ["IS_EMPTY", "IS_NOT_EMPTY"].includes(rule.operator);
    return noValueNeeded ? !!rule.property : rule.property && rule.value;
  }

  private getFilterProps() {
    const rules = this.selectedFilterByItems.rules
      .map((item) => {
        if (this.validFilter(item)) {
          let value = item.value ?? "";
          if (item.property?.is_numeric) {
            value = value.replace(",", ".");
          }
          return {
            column_key: item.property?.column_key as string,
            column_source: item.property?.column_source as ColumnSourceEnum,
            operator: item.operator as FilteringRuleENUM,
            value: value,
            name: item.property?.name,
            name_de: item.property?.name_de,
          };
        }
      })
      .filter((rec) => rec);
    if (rules.length) {
      this.validFilterRules = {
        logical_operator: this.selectedFilterByItems.logical_operator,
        rules: rules as FilteringRule[],
      };
    } else {
      if (this.validFilterRules) this.validFilterRules = null;
    }
  }

  private getSortingProps() {
    const validatedRows = this.selectedSortedByItems
      .map((item) => {
        if (item.property)
          return {
            column_key: item.property?.column_key as string,
            column_source: item.property?.column_source as ColumnSourceEnum,
            direction: item.sort as SortDirectionENUM,
          };
      })
      .filter((rec) => rec);
    if (validatedRows.length) {
      this.validSortingRules = validatedRows as SortingOption[];
    } else {
      if (this.validSortingRules) this.validSortingRules = null;
    }
  }

  async fetchObjectsList(start: number, update?: boolean, ifc_id?: string) {
    if (!ifc_id || this.dataFetchLoading) return;
    const authHeader = await getLocalAuthHeader();
    this.setDataFetchLoading(true);
    this.setErrorFetchingObjects(false);
    const props = {
      columns_to_display: this.getColumnsProps(),
    } as ObjectListIN;
    if (this.validFilterRules) {
      props.filtering = this.validFilterRules;
    }
    if (this.validSortingRules) {
      props.sorting = this.validSortingRules;
    }
    const page = Math.round(start / 100) + 1;
    await mappingEditorObjectsApi
      .mappingEditorApiV1RoutersObjectsListObjectsByFileId(
        ifc_id,
        props,
        page,
        100,
        authHeader
      )
      .then((response) => this.updateData(response.data, update))
      .catch((error) => {
        this.setErrorFetchingObjects(true);
        console.error("error.ObjectsByFileId", error);
      });
    this.updateSelectedRows();
    await this.getAllObjectsIDs(ifc_id, props.filtering);
    this.setDataFetchLoading(false);
  }

  updateSelectedRows() {
    if (this.data.items.length < this.selectedRowIndexes.length) {
      this.selectedRowIndexes.splice(this.data.items.length);
      this.setSelectedRows(this.selectedRowIndexes);
    }
  }

  private getColumnsProps() {
    return this.visibleColumns[0].columns.map((item) => ({
      column_key: item.column_key,
      column_source: item.column_source,
    })) as ColumnToDisplay[];
  }

  async fetchColumns(ifc_id?: string): Promise<DynamicColumnOUT[] | void> {
    const authHeader = await getLocalAuthHeader();
    const result = await mappingEditorDynamicColumns
      .mappingEditorApiV1RoutersDynamicColumnsGetDynamicColumns(
        ifc_id,
        authHeader
      )
      .then((response) => {
        this.setColumnGroups([
          { group: GroupType.infoColumns, columns: response.data },
        ]);
        return response.data as DynamicColumnOUT[];
      })
      .catch((error) => console.error("error.GetDynamicColumns", error));
    return result;
  }

  async matchProduct(
    file_id: string | undefined,
    product_id: string | null,
    object_id?: string
  ) {
    if (!file_id) return;
    const authHeader = await getLocalAuthHeader();
    this.setShowMappingLoading(true);
    const props: MatchObjectsIN = {
      product_id,
      columns_to_display: this.getColumnsProps(),
      object_ids: [object_id ?? (this.currentRow?.original as SingleObject).id],
    };
    return await mappingEditorObjectsApi
      .mappingEditorApiV1RoutersObjectsMatchObjects(file_id, props, authHeader)
      .then((response) => this.updateObjects(response.data.items))
      .catch((error) => console.error("error.ObjectsMatchObjects", error))
      .finally(() => this.onDoneMatching(file_id));
  }

  private onDoneMatching = async (file_id: string | undefined) => {
    await ifcMappingStore.getObjectsMappingStatus(file_id);
    this.setShowMappingLoading(false);
  };

  private updateObjects(updatedObjects: SingleObject[]) {
    updatedObjects.map((rec) => {
      const _items = [...this.data.items];
      const foundedIndex = _items.findIndex((item) => item.id === rec?.id);
      _items[foundedIndex] = { ...rec };
      this.setData({
        count: this.data.count,
        items: _items,
      });
    });
  }

  filterWithAvailableColumns() {
    return this.properties
      .map((parent) => {
        const updatedParent = { ...parent };
        updatedParent.children = parent.children
          ?.map((child) => {
            const updatedChild = { ...child };
            const filteredChild = child.children
              ?.map((grandchild) => {
                if (
                  this.visibleColumns[0].columns.find(
                    (item) => item.column_key === grandchild.column_key
                  )
                ) {
                  return grandchild;
                } else {
                  return null;
                }
              })
              .filter(Boolean) as PropertyBrowserTreeNode[];
            updatedChild.children = filteredChild;
            return updatedChild;
          })
          .filter((item) => item.children?.length);

        return updatedParent;
      })
      .filter((item) => item?.children?.length);
  }

  filterNotSelectedItems(
    data: PropertyBrowserTreeNode[],
    selectedItems: PropertyBrowserTreeNode[]
  ) {
    return data
      .map((parent) => {
        const updatedParent = { ...parent };
        updatedParent.children = parent.children
          ?.map((child) => {
            const updatedChild = { ...child };
            const filteredChild = child.children
              ?.map((grandchild) => {
                if (
                  selectedItems?.find(
                    (item) => item?.column_key === grandchild.column_key
                  ) === undefined
                ) {
                  return grandchild;
                } else {
                  return null;
                }
              })
              .filter(Boolean) as PropertyBrowserTreeNode[];
            updatedChild.children = filteredChild;
            return updatedChild;
          })
          .filter((item) => item.children?.length);

        return updatedParent;
      })
      .filter((item) => item?.children?.length);
  }

  setDefaultVisibleColumns(visibleColumnNames: string[] | null) {
    const grandChildList: PropertyBrowserTreeNode[] = [];
    this.properties.map((parent) =>
      parent.children?.map((child) =>
        child.children?.map((grandChild) => grandChildList.push(grandChild))
      )
    );
    const withDefaultColumns = grandChildList.filter(
      (child) => child.default || visibleColumnNames?.includes(child.column_key)
    );
    this.setVisibleColumns([
      { group: GroupType.infoColumns, columns: withDefaultColumns },
    ]);
  }

  get interactionDisabled(): boolean {
    return (
      this.openFilter ||
      this.openSort ||
      this.openColumnSettings.open ||
      this.openAddColumns
    );
  }

  async getProperties(file_id: string | undefined) {
    if (!file_id) return;
    const authHeader = await getLocalAuthHeader();
    return await dynamicMappingEditorApi
      .mappingEditorApiV1RoutersIndexFilterPropertyBrowserFull(
        file_id,
        authHeader
      )
      .then((response) => this.setProperties(response.data))
      .catch((error) =>
        console.error("error.FilterPropertyBrowserFull", error)
      );
  }

  async runFileOperation(
    file_id: string | undefined,
    operation_type: OperationType,
    produc_id?: string
  ) {
    if (!file_id) return;
    const authHeader = await getLocalAuthHeader();
    this.setShowMappingLoading(true);
    const props: FileOperationIN = {
      operation_type: operation_type,
      columns_to_display: this.getColumnsProps(),
    };
    if (this.validFilterRules) props.filtering = this.validFilterRules;
    if (produc_id) props.product_uuid = produc_id;
    await fileOperationsApi
      .mappingEditorApiV1RoutersFileOperationsRunFileOperations(
        file_id,
        props,
        authHeader
      )
      .catch((error) =>
        console.error(error, "error.FileOperationsRunFileOperations")
      )
      .finally(() => this.onDoneMatching(file_id));
  }

  async runSelectedObjectOperations(
    file_id: string | undefined,
    operation_type: OperationType,
    produc_id?: string
  ) {
    const authHeader = await getLocalAuthHeader();
    this.setShowMappingLoading(true);
    const props: ObjectOperationIN = {
      operation_type: operation_type,
      columns_to_display: this.getColumnsProps(),
      object_ids: this.selectedRows.map((item) => item.id),
      product_uuid: produc_id,
    };
    await objectOperationsApi
      .mappingEditorApiV1RoutersObjectOperationsRunObjectOperations(
        props,
        authHeader
      )
      .then((response) => this.updateObjects(response.data.items))
      .catch((error) =>
        console.error(error, "error.FileOperationsRunFileOperations")
      )
      .finally(() => this.onDoneMatching(file_id));
  }

  async getAllObjectsIDs(
    file_id: string | undefined,
    filtering?: Filtering | null
  ) {
    const authHeader = await getLocalAuthHeader();
    if (!file_id) return;
    await mappingEditorObjectsApi
      .mappingEditorApiV1RoutersObjectsGetAllObjects(
        file_id,
        { filtering: filtering },
        authHeader
      )
      .then(
        (response) =>
          (this.allObjectsIfcIDs = response.data.map(
            (item) => item.ifc_global_id as string
          ))
      )
      .catch((error) => console.error(error, "error.GetAllObjects"));
  }

  constructor() {
    makeAutoObservable(this);
    // set default sorting
    this.getSortingProps();
  }

  static instance: DMEStore;

  static getInstance(): DMEStore {
    if (!DMEStore.instance) {
      DMEStore.instance = new DMEStore();
    }
    return DMEStore.instance;
  }
}

export const dynamicMEStore = DMEStore.getInstance();
