import {
  Entity,
  NavCubePlugin,
  TreeViewPlugin,
  Viewer,
  XKTLoaderPlugin,
} from "@xeokit/xeokit-sdk";
import {
  inventoryMappingEditorAPI,
  InventoryPhysicalObjectDetailOut,
} from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { makeAutoObservable } from "mobx";

class BimViewerStore {
  currentObject: Entity | undefined = undefined;
  objectDetail: InventoryPhysicalObjectDetailOut[] | undefined = undefined;
  viewer: Viewer | undefined = undefined;
  fileObjectsIfcIDs: string[] = [];
  isLoaded: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchDetailsInfo(ifcId: string, objectId: string) {
    const authHeader = await getLocalAuthHeader();
    await inventoryMappingEditorAPI
      .inventoryApiV1RoutersMappingEditorGetPhysicalObjectDetailsWithGuid(
        ifcId,
        objectId,
        authHeader
      )
      .then((response) => {
        this.setObjectDetail(response.data);
      })
      .catch((error) => console.error("Failed to fetchDetailsInfo:", error));
  }

  setCurrentObject(currentObject: Entity | undefined) {
    this.currentObject = currentObject;
  }

  setObjectDetail(
    objectDetail: InventoryPhysicalObjectDetailOut[] | undefined
  ) {
    this.objectDetail = objectDetail;
  }

  setViewer(viewer: Viewer) {
    this.viewer = viewer;
  }

  createViewer(canvasId: string) {
    const viewer = new Viewer({
      canvasId: canvasId,
      transparent: true,
    });
    viewer.scene.gammaInput = true;
    viewer.scene.gammaOutput = true;
    viewer.cameraControl.followPointer = true;
    viewer.scene.camera.eye = [10.45, 17.38, -98.31];
    viewer.scene.camera.look = [43.09, 0.5, -26.76];
    viewer.scene.camera.up = [0.06, 0.96, 0.16];
    viewer.cameraFlight.fitFOV = 25;
    viewer.scene.xrayMaterial.fillAlpha = 0.1;
    viewer.scene.xrayMaterial.fillColor = [0, 0, 0];
    viewer.scene.xrayMaterial.edgeAlpha = 0.8;
    viewer.scene.xrayMaterial.edgeColor = [1, 1, 1];
    viewer.scene.selectedMaterial.fill = true;
    viewer.scene.selectedMaterial.edges = true;
    viewer.scene.selectedMaterial.fillAlpha = 0.9;
    viewer.scene.selectedMaterial.edgeAlpha = 0.6;
    viewer.scene.selectedMaterial.fillColor = [0.6, 0.965, 0.894];
    viewer.scene.selectedMaterial.edgeColor = [0.518, 0.831, 0.769];
    viewer.scene.highlightMaterial.fill = true;
    viewer.scene.highlightMaterial.edges = true;
    viewer.scene.highlightMaterial.fillAlpha = 0.9;
    viewer.scene.highlightMaterial.edgeAlpha = 0.6;
    viewer.scene.highlightMaterial.fillColor = [0.984, 0.812, 0.91];
    viewer.scene.highlightMaterial.edgeColor = [0.89, 0.729, 0.82];
    return viewer;
  }

  loadFile(fileSrc: string) {
    const viewer = this.viewer;
    if (!viewer) return;
    const xktLoader = new XKTLoaderPlugin(viewer);
    const sceneModel = xktLoader.load({
      src: fileSrc,
      edges: true,
      rotation: [0, 0, 0],
      scale: [2, 2, 2],
      origin: [40, -20, 20],
    });

    sceneModel.on("loaded", () => {
      performance.now();
      this.zoomToBuilding();
      this.fileObjectsIfcIDs = this.getFileObjectsIfcIDs();
      this.isLoaded = true;
    });
    return sceneModel;
  }

  reset() {
    this.isLoaded = false;
  }

  zoomToBuilding() {
    if (this.viewer) {
      this.viewer.scene.setObjectsVisible(this.viewer.scene.objectIds, true);
      this.viewer.cameraFlight.flyTo({
        aabb: this.viewer.scene.getAABB(this.fileObjectsIfcIDs),
      });
    }
  }

  getFileObjectsIfcIDs() {
    const ifSiteID = this.viewer?.metaScene.getObjectIDsByType("IfcSite")[0];
    const objetcIDs =
      ifSiteID && this.viewer?.metaScene.getObjectIDsInSubtree(ifSiteID);
    return objetcIDs || [];
  }

  handleObjectsOnclick(onClick: (object: Entity) => void) {
    if (!this.viewer) return;
    let lastEntity: Entity | null = null;
    this.viewer.cameraControl.on("picked", (pickResult) => {
      if (!pickResult.entity) {
        return;
      }
      if (!lastEntity || pickResult.entity.id !== lastEntity.id) {
        if (lastEntity) {
          lastEntity.selected = false;
        }

        lastEntity = pickResult.entity;
        pickResult.entity.selected = true;
        this.viewer?.scene.setObjectsXRayed(this.fileObjectsIfcIDs, true);
        onClick(pickResult.entity);
      }
    });
  }

  addNavCube() {
    if (!this.viewer) return;
    const navCube = new NavCubePlugin(this.viewer, {
      canvasId: "myNavCubeCanvas",
      visible: true,
      backColor: "#fff",
      frontColor: "#fff",
      leftColor: "#fff",
      rightColor: "#fff",
      topColor: "#fff",
      bottomColor: "#fff",
    });
    return navCube;
  }

  addTreeView(
    treeViewContainer: HTMLDivElement | null,
    hierarchy: "containment" | "storeys" | "types"
  ) {
    const viewer = this.viewer;
    if (!treeViewContainer || !viewer) return;
    const treeView = new TreeViewPlugin(viewer, {
      containerElement: treeViewContainer,
      autoExpandDepth: 1, // Initially expand tree three storeys deep
      hierarchy: hierarchy,
      sortNodes: true,
    });

    this.treeNodeTitleClickAction(treeView);
    return treeView;
  }

  treeNodeTitleClickAction(treeView: TreeViewPlugin) {
    return treeView.on("nodeTitleClicked", (e) => {
      if (!this.viewer) return;
      const scene = this.viewer.scene;
      const objectIds: string[] = [];
      e.treeViewPlugin.withNodeTree(e.treeViewNode, (treeViewNode) => {
        if (treeViewNode.objectId) {
          objectIds.push(treeViewNode.objectId as string);
        }
      });
      e.treeViewPlugin.unShowNode();
      scene.setObjectsXRayed(scene.objectIds, true);
      scene.setObjectsVisible(scene.objectIds, true);
      scene.setObjectsXRayed(objectIds, false);
      this.viewer.cameraFlight.flyTo(
        {
          aabb: scene.getAABB(objectIds),
          duration: 0.5,
        },
        () => {
          setTimeout(function () {
            scene.setObjectsVisible(scene.xrayedObjectIds, false);
            scene.setObjectsXRayed(scene.xrayedObjectIds, false);
          }, 500);
        }
      );
    });
  }

  setObjectSelectStatus(status: boolean, removeXRay?: boolean) {
    if (this.currentObject) {
      this.currentObject.selected = status;
      this.setCurrentObject(undefined);
      removeXRay &&
        this.viewer?.scene.setObjectsXRayed(this.fileObjectsIfcIDs, false);
    }
  }

  setHighlighted(
    allIfcIDs: string[],
    selectedIDs: string[],
    allChecked: boolean
  ) {
    if (selectedIDs.length) {
      this.viewer?.scene.setObjectsXRayed(this.fileObjectsIfcIDs, true);
      this.viewer?.scene.setObjectsHighlighted(this.fileObjectsIfcIDs, false);
      if (allChecked) {
        this.viewer?.scene.setObjectsHighlighted(allIfcIDs, true);
      } else {
        this.viewer?.scene.setObjectsHighlighted(selectedIDs, true);
      }
    } else {
      this.viewer?.scene.setObjectsHighlighted(this.fileObjectsIfcIDs, false);
      !this.currentObject &&
        this.viewer?.scene.setObjectsXRayed(this.fileObjectsIfcIDs, false);
    }
  }

  zoomToSelected(ifcIDs: string[]) {
    this.viewer?.cameraFlight.flyTo({
      aabb: this.viewer.scene.getAABB(ifcIDs),
    });
  }

  setSelected(currentIDs: string[], zoom?: boolean) {
    if (currentIDs.length) {
      this.viewer?.scene.setObjectsXRayed(this.fileObjectsIfcIDs, true);
      this.viewer?.scene.setObjectsSelected(this.fileObjectsIfcIDs, false);
      this.viewer?.scene.setObjectsSelected(currentIDs, true);
      zoom && this.zoomToSelected(currentIDs);
    } else {
      this.removeSelected();
    }
  }

  removeSelected(selectedIDs?: string[]) {
    if (this.currentObject === undefined) {
      if (selectedIDs?.length) {
        this.zoomToSelected(selectedIDs);
      } else {
        this.viewer?.scene.setObjectsXRayed(this.fileObjectsIfcIDs, false);
        this.zoomToBuilding();
      }
      this.viewer?.scene.setObjectsSelected(this.fileObjectsIfcIDs, false);
    } else {
      this.viewer?.scene.setObjectsSelected(this.fileObjectsIfcIDs, false);
      this.viewer?.scene.setObjectsSelected(
        [String(this.currentObject.id)],
        true
      );
    }
  }

  setHovered(
    currentID: string | null,
    clickedRowID: string | null | undefined,
    hasSelection: boolean
  ) {
    if (currentID) {
      const notSelectedObjects = this.fileObjectsIfcIDs.filter(
        (item) => item !== clickedRowID
      );
      notSelectedObjects.length &&
        this.viewer?.scene.setObjectsSelected(notSelectedObjects, false);
      const currentObject = this.viewer?.scene.objects[currentID];
      if (currentObject) currentObject.selected = true;
      this.viewer?.scene.setObjectsXRayed(this.fileObjectsIfcIDs, true);
    } else if (!currentID && !clickedRowID) {
      !hasSelection &&
        this.viewer?.scene.setObjectsXRayed(this.fileObjectsIfcIDs, false);
      this.viewer?.scene.setObjectsSelected(this.fileObjectsIfcIDs, false);
    }
  }

  static instance: BimViewerStore;

  static getInstance(): BimViewerStore {
    if (!BimViewerStore.instance) {
      BimViewerStore.instance = new BimViewerStore();
    }
    return BimViewerStore.instance;
  }
}

export const bimViewerStore = BimViewerStore.getInstance();
