import React from "react";
import { observer } from "mobx-react-lite";
import { IconChevronDown } from "@tabler/icons-react";
import clsx from "clsx";
import FilterMenu from "./FilterMenu";
import { PropertyBrowserTreeNode } from "api-client";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";

export interface FilterRule {
  property: PropertyBrowserTreeNode | undefined;
  operator: string;
  value: string;
}

export default observer(function FilterByPropertyBrowser() {
  const [cursorPosition, setCursorPosition] = React.useState({ x: 0, y: 0 });
  const closeView = () => dynamicMEStore.setOpenFilter(false);

  React.useEffect(() => {
    document.addEventListener("click", closeView);
    return () => {
      document.removeEventListener("click", closeView);
    };
  }, []);

  const handleOnClick = (e: { clientX: number; clientY: number }) => {
    dynamicMEStore.setOpenFilter(!dynamicMEStore.openFilter);
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <div className="overflow-visible" onClick={(e) => e.stopPropagation()}>
      <IconChevronDown
        className={clsx(
          "h-5 w-5 cursor-pointer",
          dynamicMEStore.openFilter ? "rotate-180" : ""
        )}
        aria-hidden="true"
        onClick={handleOnClick}
      />
      {dynamicMEStore.openFilter ? (
        <FilterMenu
          cursorPosition={cursorPosition}
          items={dynamicMEStore.properties}
        />
      ) : null}
    </div>
  );
});
