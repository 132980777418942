import React from "react";
import { useTranslation } from "react-i18next";
import { IconGripVertical, IconPlus, IconTrash } from "@tabler/icons-react";
import clsx from "clsx";
import SimpleSelect from "components/SimpleSelect";
import { EMPTY_SORT_ROW, SORT_OPTIONS } from "../../const";
import { PropertyBrowserTreeNode } from "api-client";
import useWindowOverflow from "hooks/useWindowOverflow";
import { observer } from "mobx-react-lite";
import { SortProps } from ".";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import SimpleColumnsMenu from "./SimpleExistingMenu";
import LinearLoading from "components/LinearLoading";

function SortMenu({
  cursorPosition,
  items,
}: {
  cursorPosition: { x: number; y: number };
  items: PropertyBrowserTreeNode[];
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { getLeftPos, containerRef } = useWindowOverflow();
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);

  const onChangeProperty = (value: PropertyBrowserTreeNode, index: number) => {
    const rows = [...dynamicMEStore.selectedSortedByItems];
    rows[index].property = value;
    dynamicMEStore.setSelectedSortedByItems([...rows]);
  };

  const onChangeSortOption = (value: string | undefined, index: number) => {
    const rows = [...dynamicMEStore.selectedSortedByItems];
    rows[index].sort = String(value);
    dynamicMEStore.setSelectedSortedByItems([...rows]);
  };

  const addRow = () => {
    const rows = [
      ...dynamicMEStore.selectedSortedByItems,
      {
        ...EMPTY_SORT_ROW,
        id: String(dynamicMEStore.selectedSortedByItems.length),
      },
    ];
    dynamicMEStore.setSelectedSortedByItems(rows);
    scrollAreaRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const removeAllRules = () =>
    dynamicMEStore.setSelectedSortedByItems([{ ...EMPTY_SORT_ROW }]);

  const removeCurrentRow = (index: number) => {
    const rows = [...dynamicMEStore.selectedSortedByItems];
    rows.splice(index, 1);
    dynamicMEStore.setSelectedSortedByItems([...rows]);
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(dynamicMEStore.selectedSortedByItems);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);
    dynamicMEStore.setSelectedSortedByItems(
      reorderedItems as unknown as SortProps[]
    );
  };

  const sortRow = (row: SortProps, index: number) => {
    return (
      <Draggable key={row?.id} draggableId={row?.id} index={index}>
        {(provided) => (
          <li
            className="flex items-center p-2 gap-2 min-w-[400px] hover:bg-indigo-100 transition group"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ ...provided.draggableProps.style }}
          >
            <IconGripVertical className="group-hover:text-indigo-700" />
            <SimpleColumnsMenu<PropertyBrowserTreeNode>
              isEng={isEng}
              handleSelect={(value) => onChangeProperty(value, index)}
              items={items}
              current={row?.property}
              optionsClassName="!max-w-fit min-w-[260px] bg-white"
              containerClassName="ml-1 relative"
            />
            <SimpleSelect
              options={SORT_OPTIONS}
              noMargin
              className="!mb-0 !mt-0"
              onChange={(value) => onChangeSortOption(value, index)}
              current={row?.sort}
            />
            <IconTrash
              className={clsx(
                "cursor-pointer ml-2 text-gray-500 min-w-[20px]",
                dynamicMEStore.selectedSortedByItems.length === 1
                  ? "cursor-not-allowed pointer-events-none opacity-50"
                  : "hover:text-gray-900"
              )}
              size={20}
              onClick={() =>
                dynamicMEStore.selectedSortedByItems.length > 1 &&
                removeCurrentRow(index)
              }
            />
          </li>
        )}
      </Draggable>
    );
  };

  const rows = dynamicMEStore.selectedSortedByItems;
  const hasAnyPropertySet =
    rows.map((e) => e.property).filter(Boolean).length > 0;

  return (
    <div
      className={clsx(
        "absolute z-5 my-1 w-full overflow-visible rounded-md bg-white text-sm text-gray-700 !max-w-fit",
        "shadow-lg border border-gray-300 min-w-auto w-auto max-w-md focus:outline-none sm:text-sm"
      )}
      ref={containerRef}
      style={{
        left: getLeftPos(cursorPosition.x, 20),
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={clsx("pt-1 max-h-[325px]", {
          "overflow-y-auto": rows.length > 6,
        })}
      >
        <div className="h-1">
          {dynamicMEStore.dataFetchLoading && <LinearLoading type="auto" />}
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable-1">
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ listStyle: "none", padding: 0 }}
              >
                {rows.map((row, index) => sortRow(row, index))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <div className="h-1.5" ref={scrollAreaRef}></div>
      </div>
      <div className="border-t border-gray-200 text-gray-700 transition hover:text-gray-900">
        <div
          className="flex px-4 py-2 items-center cursor-pointer"
          onClick={addRow}
        >
          <IconPlus className="mr-2" size={20} />
          <span>{t("mappingEditor.addProperty")}</span>
        </div>
        <div
          className={clsx(
            "flex px-4 py-2 items-center cursor-pointer",
            !hasAnyPropertySet && "pointer-events-none text-gray-300"
          )}
          onClick={removeAllRules}
        >
          <IconTrash className="mr-2" size={20} />
          <span>{t("mappingEditor.deleteSorting")}</span>
        </div>
      </div>
    </div>
  );
}

export default observer(SortMenu);
