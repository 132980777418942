import {
  // Icon3dCubeSphere,
  // IconAlertCircle,
  IconCircle,
  IconCircleCheck,
  // IconCircleX,
  // IconFilter,
  // IconPlayerPlay,
  // IconSearch,
  // IconSparkles,
} from "@tabler/icons-react";
import { Row } from "@tanstack/react-table";
import { DynamicColumnOUT, PropertyBrowserTreeNode } from "api-client";
import { useTranslation } from "react-i18next";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { FilterRule } from "./Filters/FilterByPropertyBrowser";
import { EMPTY_FILTER_RULE } from "./const";
import { ColumnVisibilityState, GroupType } from "store/IfcMapping/DME/types";
import {
  getVisibleColumnKeys,
  replaceVisibilitySettings,
  saveColumnState,
} from "./columnVisibility";

const useMappingEditor = () => {
  const { t } = useTranslation();

  const checkRow = (row?: Row<unknown>) => {
    if (!row) return;
    const selectedIndexes = [
      ...dynamicMEStore.selectedRowIndexes,
      String(row?.index),
    ].sort();
    dynamicMEStore.setSelectedRows(Array.from(new Set(selectedIndexes)));
  };

  const unCheckRow = (row?: Row<unknown>) => {
    if (!row) return;
    const selectedIndexes = dynamicMEStore.selectedRowIndexes.filter(
      (item) => item != String(row.index)
    );
    dynamicMEStore.setSelectedRows(selectedIndexes);
  };

  const contextMenuOptions = [
    {
      title: t("mappingEditor.checkProductLink"),
      options: [
        {
          label: t("mappingEditor.markAsChecked"),
          onClick: checkRow,
          icon: <IconCircleCheck size={18} />,
        },
        {
          label: t("mappingEditor.markAsNotChecked"),
          onClick: unCheckRow,
          icon: <IconCircle size={18} />,
        },
      ],
    },
    // {
    //   title: t("mappingEditor.defineRelevance"),
    //   options: [
    //     {
    //       label: t("mappingEditor.markAsRelevant"),
    //       onClick: checkRow,
    //       icon: <IconAlertCircle size={18} />,
    //     },
    //     {
    //       label: t("mappingEditor.markAsNotRelevant"),
    //       onClick: checkRow,
    //       icon: <IconCircleX size={18} />,
    //     },
    //   ],
    // },
    // {
    //   title: t("mappingEditor.performProductLinking"),
    //   options: [
    //     {
    //       label: t("mappingEditor.linkRegularlyBased"),
    //       onClick: checkRow,
    //       icon: <IconPlayerPlay size={18} />,
    //     },
    //     {
    //       label: t("mappingEditor.linkAIBased"),
    //       onClick: checkRow,
    //       icon: <IconSparkles size={18} />,
    //     },
    //     {
    //       label: t("mappingEditor.linkManually"),
    //       onClick: checkRow,
    //       icon: <IconSearch size={18} />,
    //     },
    //   ],
    // },
    // {
    //   title: t("mappingEditor.miscellaneous"),
    //   options: [
    //     {
    //       label: t("mappingEditor.highlightIn3DView"),
    //       onClick: checkRow,
    //       icon: <Icon3dCubeSphere size={18} />,
    //     },
    //     {
    //       label: t("mappingEditor.preFilterInTable"),
    //       onClick: checkRow,
    //       icon: <IconFilter size={18} />,
    //     },
    //   ],
    // },
  ];

  const buildColumnsVisibilityState = (): ColumnVisibilityState => {
    /**
     * Builds the current state using the available column groups and
     * visible columns
     */
    const previousState: ColumnVisibilityState = Object.keys(
      dynamicMEStore.columnsVisibilityState
    ).reduce((r, c) => Object.assign(r, { [c]: false }), {});

    const visibleColumnKeys = new Set(
      dynamicMEStore.visibleColumns
        .map((e) => e.columns)
        .flat()
        .map((e) => e.column_key)
    );

    const allColumns = dynamicMEStore.columnGroups
      .map((e) => e.columns)
      .flat()
      .map((e) => {
        return { [e.key_name]: visibleColumnKeys.has(e.key_name) };
      });

    const reducedList = allColumns.reduce(
      (r, c) => Object.assign(r, c),
      previousState
    );
    return reducedList;
  };

  const updateColumnsVisibilitySavedState = (
    newColumns: DynamicColumnOUT[]
  ) => {
    /**
     * Updates the available columns and the visible columns in one operation
     */
    dynamicMEStore.setColumnGroups([
      { group: GroupType.infoColumns, columns: newColumns },
    ]);
    const newState = replaceVisibilitySettings(newColumns);
    const visibleColumns = getVisibleColumnKeys(newState);
    dynamicMEStore.setDefaultVisibleColumns(visibleColumns);
    saveColumnsVisibilityState();
  };

  const saveColumnsVisibilityState = () => {
    /**
     * Builds the current state of column visibility from the DMEStore
     * and save its in the local storage and in the DMEStore
     */
    const currentState = buildColumnsVisibilityState();
    currentState && dynamicMEStore.setColumnsVisibilityState(currentState);
    saveColumnState(currentState);
  };

  const reorderListOfObjects = (
    listOfObjects: PropertyBrowserTreeNode[],
    sortingOrder: string[]
  ) => {
    listOfObjects.sort((a, b) => {
      const indexA = sortingOrder.indexOf(a.column_key);
      const indexB = sortingOrder.indexOf(b.column_key);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      } else if (indexA !== -1) {
        return -1;
      } else if (indexB !== -1) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  function getColumnsOrderSavedState() {
    let orderState = JSON.parse(
      localStorage.getItem("DME_columns_order") ?? "null"
    );
    if (!orderState) {
      orderState = defaultColumnOrder();
    }

    reorderListOfObjects(dynamicMEStore.visibleColumns[0]?.columns, orderState);
    saveColumnsOrder();
  }

  function defaultColumnOrder(): string[] {
    const staticOrder: string[] = [
      "physical_object.component",
      "physical_object.material",
      "physical_object.area",
      "physical_object.volume",
      "match_calculations.mass",
      "match_calculations.gwp",
    ];
    const columnVisibility = buildColumnsVisibilityState();
    return staticOrder.filter((k) => columnVisibility[k]);
  }

  function saveColumnsOrder() {
    const columnsOrder = dynamicMEStore.visibleColumns[0].columns.map(
      (item) => item.column_key
    );
    dynamicMEStore.setColumnsOrder(columnsOrder);
    localStorage.setItem("DME_columns_order", JSON.stringify(columnsOrder));
  }

  function updateRules(rules: FilterRule[]) {
    dynamicMEStore.setSelectedFilterByItems({
      ...dynamicMEStore.selectedFilterByItems,
      rules: [...rules],
    });
  }

  const removeAllFilterRules = () => updateRules([{ ...EMPTY_FILTER_RULE }]);

  return {
    contextMenuOptions,
    buildColumnsVisibilityState,
    updateColumnsVisibilitySavedState,
    saveColumnsVisibilityState,
    getColumnsOrderSavedState,
    saveColumnsOrder,
    updateRules,
    removeAllFilterRules,
  };
};

export default useMappingEditor;
