import React from "react";
import DropdownBrowser, { DropdownOption } from "components/DropdownBrowser";
import { useTranslation } from "react-i18next";
import {
  IconEye,
  IconEyeOff,
  IconEyePlus,
  IconGripVertical,
} from "@tabler/icons-react";
import clsx from "clsx";
import { PropertyBrowserTreeNode } from "api-client";
import useWindowOverflow from "hooks/useWindowOverflow";
import { observer } from "mobx-react-lite";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import ReactDOM from "react-dom";
import LinearLoading from "components/LinearLoading";
import useMappingEditor from "../useMappingEditor";

function SelectorMenu({
  cursorPosition,
  close,
  onAddColumn,
  filteredItems,
}: {
  cursorPosition: { x: number; y: number };
  close: () => void;
  onAddColumn: (value: DropdownOption[]) => void;
  filteredItems: PropertyBrowserTreeNode[];
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { getLeftPos, containerRef } = useWindowOverflow();
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);
  const { saveColumnsVisibilityState, saveColumnsOrder } = useMappingEditor();

  const removeCurrentRow = (id: string) => {
    const updatedList = dynamicMEStore.visibleColumns[0].columns.filter(
      (item) => item.id !== id
    );
    dynamicMEStore.visibleColumns[0].columns = updatedList;
    dynamicMEStore.setVisibleColumns(dynamicMEStore.visibleColumns);
    saveColumnsVisibilityState();
    saveColumnsOrder();
    close();
  };

  const onSelectColumn = async (value: DropdownOption[]) => {
    onAddColumn(value);
    scrollAreaRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(dynamicMEStore.visibleColumns[0].columns);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);
    dynamicMEStore.visibleColumns[0].columns = reorderedItems;
    dynamicMEStore.setVisibleColumns(dynamicMEStore.visibleColumns);
    saveColumnsOrder();
    close();
  };

  const rows = dynamicMEStore.visibleColumns[0].columns;

  const rowContent = (row: PropertyBrowserTreeNode, index: number) => {
    return (
      <Draggable key={row?.id} draggableId={row?.id} index={index}>
        {(provided) => (
          <li
            className={clsx(
              "flex group items-center px-4 py-2 gap-2 justify-between ",
              "hover:bg-indigo-50 hover:text-indigo-700",
              { "opacity-70": rows?.length === 1 }
            )}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ ...provided.draggableProps.style }}
          >
            <div className="flex items-center whitespace-nowrap">
              <IconGripVertical size={20} className="mr-2 min-w-[20px]" />
              <span>{isEng ? row.name : row.name_de}</span>
            </div>
            <IconEyeOff
              className={clsx(
                "cursor-pointer ml-1 min-w-[20px] invisible",
                rows?.length === 1 ? "hidden" : "group-hover:visible"
              )}
              size={20}
              onClick={() =>
                Number(rows?.length) > 1 && removeCurrentRow(row.id)
              }
            />
          </li>
        )}
      </Draggable>
    );
  };

  return ReactDOM.createPortal(
    <div
      className={clsx(
        "absolute z-5 my-1 w-full overflow-visible rounded-md bg-white text-sm text-gray-700 !max-w-fit",
        "shadow-lg border border-gray-300 min-w-auto w-auto max-w-md focus:outline-none sm:text-sm"
      )}
      ref={containerRef}
      style={{
        left: getLeftPos(cursorPosition.x, 20),
        top: cursorPosition.y,
        zIndex: 20,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex items-center px-4 py-1 border-b border-gray-300 bg-gray-50">
        <IconEye className="mr-2 text-gray-500" />
        {t("mappingEditor.displayedColumns")}
      </div>

      {rows ? (
        <div
          className={clsx("pb-1 max-h-[325px] relative", {
            "overflow-y-auto": rows.length > 6,
          })}
        >
          <div className="h-1">
            {dynamicMEStore.dataFetchLoading && <LinearLoading type="auto" />}
          </div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable-1">
              {(provided) => (
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ listStyle: "none", padding: 0 }}
                >
                  {rows.map((row, index) => rowContent(row, index))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
          <div className="h-[1px]" ref={scrollAreaRef}></div>
        </div>
      ) : null}
      <div className="border-t border-gray-200 text-gray-700 transition hover:text-gray-900">
        <DropdownBrowser<PropertyBrowserTreeNode>
          displayKey={isEng ? "name" : "name_de"}
          handleSelect={onSelectColumn}
          items={filteredItems}
          checkedItems={dynamicMEStore.visibleColumns[0].columns}
          placeholder={t("mappingEditor.findProperty")}
          optionsClassName="!max-w-fit min-w-[246px] !right-0"
          fixedPos
          titleOrIcon={
            <div className="flex w-full px-4 py-2 items-center cursor-pointer hover:bg-indigo-50 hover:text-indigo-700 whitespace-nowrap">
              <IconEyePlus className="mr-2" size={20} />
              <span>{t("mappingEditor.addProperty")}</span>
            </div>
          }
        />
      </div>
    </div>,
    document.body
  );
}

export default observer(SelectorMenu);
