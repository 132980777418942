import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { PropertyBrowserTreeNode } from "api-client";

export interface SimpleColumnsMenuProps<T> {
  items: T[];
  handleSelect: (value: T) => void;
  isEng: boolean;
  containerClassName?: string;
  optionsClassName?: string;
  current: T | undefined;
}

export default function SimpleColumnsMenu<Item extends PropertyBrowserTreeNode>(
  props: SimpleColumnsMenuProps<Item>
) {
  //const [selected] = useState<Item | null>(props.current || null);

  return (
    <div className={clsx(props.containerClassName)}>
      <Listbox value={props.current} onChange={props.handleSelect}>
        <Listbox.Button
          className={
            "flex items-center rounded-md border border-gray-300 px-2 py-1.5 h-9 !max-w-fit min-w-[260px] bg-white"
          }
        >
          {props.isEng ? props.current?.name : props.current?.name_de}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="z-10 absolute mt-1 max-h-60 !max-w-fit min-w-[260px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm ">
            {props.items.map((item, itemIdx) => (
              <Listbox.Option
                key={itemIdx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active
                      ? "bg-indigo-50 text-indigo-700"
                      : "bg-white text-gray-900"
                  }`
                }
                value={item}
              >
                {({ selected }) => (
                  <div className="flex flex-col">
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {props.isEng ? item.name : item.name_de}
                    </span>
                    <span className={`block truncate text-xs`}>
                      {props.isEng ? item.group_name : item.group_name_de}
                    </span>
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
}
