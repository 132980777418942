export const PROPERTY_RULE_OPERATORS = [
  { id: "IS", name: "is", name_de: "Ist" },
  { id: "IS_NOT", name: "is not", name_de: "Ist nicht" },
  { id: "CONTAINS", name: "Contains", name_de: "Enthält" },
  { id: "DOES_NOT_CONTAIN", name: "Contains not", name_de: "Enthält nicht" },
  { id: "ENDS_WITH", name: "Ends with", name_de: "Endet mit" },
  { id: "IS_EMPTY", name: "Is empty", name_de: "Ist leer" },
  { id: "IS_NOT_EMPTY", name: "Is not empty", name_de: "Ist nicht leer" },
  { id: "LESS_THAN", name: "Less than", name_de: "Weniger als" },
  {
    id: "LESS_THAN_OR_EQUAL",
    name: "Less than or equal",
    name_de: "Weniger oder gleich",
  },
  {
    id: "GREATER_THAN_OR_EQUAL",
    name: "Greater than or equal",
    name_de: "Größer oder gleich",
  },
  { id: "GREATER_THAN", name: "Greater than", name_de: "Größer als" },
];

const NUMERIC_OPERATORS = new Set([
  "IS",
  "IS_NOT",
  "LESS_THAN",
  "LESS_THAN_OR_EQUAL",
  "GREATER_THAN_OR_EQUAL",
  "GREATER_THAN",
  "IS_EMPTY",
  "IS_NOT_EMPTY",
]);
const TEXTUAL_OPERATORS = new Set([
  "IS",
  "IS_NOT",
  "CONTAINS",
  "DOES_NOT_CONTAIN",
  "ENDS_WITH",
  "IS_EMPTY",
  "IS_NOT_EMPTY",
]);

export const PROPERTY_RULE_OPERATORS_NUMERIC = PROPERTY_RULE_OPERATORS.filter(
  (e) => NUMERIC_OPERATORS.has(e.id)
);
export const PROPERTY_RULE_OPERATORS_TEXTUAL = PROPERTY_RULE_OPERATORS.filter(
  (e) => TEXTUAL_OPERATORS.has(e.id)
);

export const LOGICAL_OPERATORS = [
  { id: "AND", name: "and", name_de: "Und" },
  { id: "OR", name: "or", name_de: "Oder" },
];

export const EMPTY_FILTER_RULE = {
  property: undefined,
  operator: "IS",
  value: "",
};

export const EMPTY_SORT_ROW = {
  id: "0",
  property: undefined,
  sort: "ASC",
};

export const DEFAULT_SORT_ROW = {
  id: "0",
  property: {
    id: "1",
    column_key: "physical_object.component",
    column_source: "PhysicalObject",
    name: "Name",
    name_de: "Name",
    group_name: "Physical Object",
    group_name_de: "Physical Object",
    default: true,
    is_numeric: false,
  },
  sort: "ASC",
};

export const SORT_OPTIONS = [
  { id: "ASC", name: "Ascending", name_de: "Aufsteigend" },
  { id: "DESC", name: "Descending", name_de: "Absteigend" },
];

export const DME_HELP_URL =
  "https://concular.notion.site/Wie-kann-ich-Produktverkn-pfungen-im-Mapping-Editor-ausf-hren-8951b61aef2f4a4c965f9ba22e60521a?pvs=4";
